import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import Profile from "../images/pro.png"; // Import the profile image

import { useCookies } from "react-cookie";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { getUserData, updateUserData } from "../redux/action/userAction";
import {
  getDietitian,
  updateQuickNotes,
} from "../redux/action/insurenceProviderAction";
import { DIETITIAN_DATA } from "../utils/constants/insurence-provider";
import moment from "moment";
import "../css/AppointmentConfirmation.css";
import Spinner from "./Spinner";
import TransitionAnimation from "./Animation/TransitionAnimation";
import CommonButton from "./Button/Button";
import { Alert } from "@mui/material";

const Appointmentconfirmation = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["evuid"]);
  const dispatch = useDispatch();
  const progressValue = 100;
  const navigate = useNavigate();
  const [focusedField, setFocusedField] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [successFileMessage, setSuccessFileMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [appointmentData, setAppointmentData] = useState({});
  const [notes, setQuickNotes] = useState("");
  const [error, setError] = useState("");
  const [insuranceId, setInsuranceId] = useState(null);

  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
  const dietitianData = useSelector((state) => state.insurence[DIETITIAN_DATA]);

  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      // Redirect the user to the specific path
      window.location.href = "https://geteverlong.com";
      removeCookie("evuid", {
        expires: new Date(new Date().getTime() - 1000),
      });
      // window.location.href = location;
    });

    return () => {
      window.removeEventListener("popstate", () => console.log("redirected"));
    };
  }, []);

  useEffect(() => {
    // Initialize GTM with your GTM container ID

    // Check if GTM has loaded before pushing data
    if (typeof window.dataLayer !== "undefined") {
      // Push data to the data layer
      window.dataLayer.push({
        event: "pageview", // Replace with the appropriate event name
        pageType: "appointment_confirmation", // Customize as needed
      });
    }
  }, []);

  const setUserDataHandler = (uData) => {
    if (uData && typeof uData.appointment_data != "undefined") {
      setAppointmentData(uData.appointment_data);
    }

    if (uData.quick_notes) {
      //setQuickNotes(uData.quick_notes);
    }

    if (uData.insurence) {
      setInsuranceId(uData.insurence.insurance_provider_id);
    }
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/appointmentconfirmation",
              })
            );
            dispatch(
              getDietitian({
                evuid: cookies["evuid"],
              })
            );
            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
            window.scrollTo(0, 0);
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      setTimeout(() => {
        window.location.href = "https://geteverlong.com/";
      }, 3000);
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = (e) => {
    setFocusedField(null);

    if (e.target.name) {
      dispatch(
        updateQuickNotes({
          evuid: cookies["evuid"],
          [e.target.name]: e.target.value,
        })
      );
    }
  };

  const handlenoteChange = (e) => {
    setQuickNotes(e.target.value);
    setError("");
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "image/heic",
      "image/heif",
      "image/jpeg", // Example image types, add more as needed
      "image/jpg", // Example image types, add more as needed
      "image/png",
      "application/pdf",
      "application/msword", // .doc
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    ];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        // File size exceeds 10 MB
        setError(
          "The file size of your referral document is too large. Please compress it or upload a smaller file."
        );
        setFileData(null); // Clear the selected file
      } else if (!allowedTypes.includes(file.type)) {
        setError(
          "File type not accepted. Please upload a PNG, JPG, PDF, DOCX, or HEIC file."
        );
      } else {
        // File size is within the limit
        setError(""); // Clear any previous error message
        setFileData(file);
      }
    }
  };

  const handleRemoveFile = () => {
    // Clear the selected file from the input element
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = null;
    }
    setFileData(null);
  };


  const handleSendButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!notes) return;

    const formBody = createFormData("quick_notes", JSON.stringify(notes));

    dispatch(
      updateQuickNotes(formBody, (resData) => {
        handleResponse("Message sent!", insuranceId !== "04271", 'note');
      })
    );
  };

  const handleUploadButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!fileData) return;

    const formBody = createFormData("referral_form", fileData);
    setUploading(true);
    scrollToMessage();

    dispatch(
      updateQuickNotes(formBody, (resData) => {
        setUploading(false);
        handleResponse("Referral uploaded!", true, 'file');
      })
    );
  };

  useEffect(() => {
    if (isUploading || successFileMessage) {
      scrollToMessage(); // Scroll to message if either is true
    }
  }, [isUploading, successFileMessage]);

  const createFormData = (key, value) => {
    const formBody = new FormData();
    formBody.append("evuid", cookies["evuid"]);
    formBody.append(key, value);
    return formBody;
  };

  const messageRef = useRef(null);

  const handleResponse = (message, shouldRemoveCookie, type) => {
    setUploading(false);
    if (type === 'note') {
      setSuccessMessage(message);
    } else if (type === 'file') {
      setSuccessFileMessage(message);
      scrollToMessage();
    }
    setTimeout(() => {
      const buttonElement = document.getElementById('visit-text');
      if (buttonElement) {
        buttonElement.scrollIntoView({ behavior: 'auto' });
        const yOffset = buttonElement.getBoundingClientRect().top;
        window.scrollTo({
          top: yOffset,
          behavior: 'smooth',
        });
      }
    }, 100);

    if (shouldRemoveCookie) {
      removeCookie("evuid", {
        expires: new Date(Date.now() - 1000),
      });
    }
  };

  const scrollToMessage = () => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setTimeout(() => {
        // Adjust the scroll position slightly after scrollIntoView
        window.scrollBy(0, 50); // Adjust `50` to the amount of extra space you want
      }, 100);
    }
  };

  return (
    <>
      <Header progressValue={progressValue} />

      {!loading && (
        <TransitionAnimation>
          <div
            style={{
              marginTop: "6rem",
            }}
            className="container">
            <div className="row">
              <div className="col-lg-7 col-md-10 col-11 text-left mx-auto mt-4">
                <div className="row top-info-div">
                  <div className="col-auto text-left">
                    <img
                      src={dietitianData.avatar_url || Profile}
                      alt="Dietitian Profile"
                      height="130px"
                      width="130px"
                      className="rounded-circle"
                    />
                  </div>

                  <div className="col text-left d-flex flex-column justify-content-center">
                    <h5 style={{ fontWeight: "540", marginBottom: "3%" }}>Session Confirmed!</h5>
                    {appointmentData && appointmentData.slot ? (
                      <h6>{moment(appointmentData.slot, 'YYYY-MM-DD HH:mm:ss Z').format('dddd, MMMM D, YYYY')}</h6>
                    ) : (
                      ""
                    )}
                    {appointmentData && appointmentData.slotTime && userData && userData.dietitian_data && userData.dietitian_data.name ? (
                      <h6>
                        {appointmentData.slotTime} with {userData.dietitian_data.name}
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-10 col-sm-12 mx-auto mt-5">
              <div className="row">
                <div className="col-10 mx-auto mt-2 text-left">
                  <p className=" h4 text-start ">Session Confirmation & Next Steps</p>
                </div>
              </div>
              <div className="row">
                <div className="col-10 mx-auto mt-2 text-left">
                  <p>
                    Please check your email for an invite to our patient portal. You’ll find a brief intake form to complete before your session, as well as the link to join. Any changes to your session can be done on the patient portal.
                  </p>
                  <p>
                    You may also include any additional details you’d like to share with your dietitian (optional).
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5" style={{ paddingLeft: "12px", paddingRight: "12px" }}>
              <div className="col-lg-6 col-md-9 col-sm-12 col-10 mx-auto">
                <div style={{ position: 'relative', width: '100%' }}>
                  <textarea
                    id="note"
                    className={`form-control inputbox ${focusedField === "note" ? "selected" : ""}`}
                    value={!successMessage ? notes : ""}
                    name="quick_notes"
                    onChange={handlenoteChange}
                    onFocus={() => handleFocus("note")}
                    onBlur={handleBlur}
                    disabled={successMessage}
                    style={{ width: '100%', resize: 'none', height: '90px', lineHeight: '1.5', padding: '10px 55px 10px 10px' }}
                  />
                  {notes && !successMessage && (
                    <button
                      onClick={handleSendButton}
                      className="send-button"
                      disabled={successMessage}
                    >
                      {'Send'}
                    </button>
                  )}
                </div>
                {successMessage && (
                  <div className="d-flex mt-3 mx-auto" style={{ display: "flex", justifyContent: "center", width: "100%" }}> {/* Use ml-auto for Bootstrap 4 */}
                    <Alert severity={"info"} sx={{
                      backgroundColor: "#E6D9F5", color: "#8548D3",
                      '& .MuiAlert-icon': {
                        color: "#8548D3",
                        marginRight: "10px"
                      }
                    }}>
                      {successMessage}
                    </Alert>
                  </div>
                )}

                {error && (
                  <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Alert
                      severity="error"
                      // className="mt-3"
                      style={{ display: "inline-flex" }}
                    >
                      {error}
                    </Alert>
                  </div>
                )}

              </div>
            </div>
            {/*insuranceId === "04271" && (
                  <div className="col-lg-7 col-md-10 col-sm-12 mx-auto mt-5">
                  <div className="row">
                    <div className="col-10 mx-auto mt-2 text-left">
                      <p className=" h4 text-start ">Upload Referral</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="image-upload-box col-10 mx-auto mt-2 text-left">
                    <CommonButton>
                          <label style={{ cursor: "pointer" }}>
                            <i class="fas fa-upload upload-icon me-2"></i> Upload
                            Referral
                            <input
                              type="file"
                              id="fileInput"
                              onChange={handleUpload}
                              class="file-input d-none"
                            />
                          </label>
                        </CommonButton>
                        {fileData && (
                          <div class="file-name" id="fileNameDisplay">
                            {fileData?.name || "-"}
                            <span className="remove-icon">
                              <i
                                className="fa fa-times"
                                onClick={handleRemoveFile}></i>
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
            )*/}
            {insuranceId === "04271" && (
              <div className="col-lg-7 col-md-10 col-sm-12 mx-auto mb-5">
                <div className="row">
                  <div className="col-10 mx-auto text-start">
                    <p className="h4">Upload Referral</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 mx-auto d-flex flex-column flex-md-row justify-content-between align-items-center">

                    {/* Choose File Button */}
                    <CommonButton
                      className="image-upload-box d-flex align-items-center justify-content-center me-md-2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',        
                        maxWidth: '308px',
                        margin: '20px auto',
                        padding: '.58rem 4.2rem',
                        transition: 'all 0.08s ease-in-out 0s',
                        border: '2px dotted #888',
                        color: '#888',
                        backgroundColor: '#FFFFFF',
                        position: 'relative',
                      }}
                      disabled={false}
                      onClick={() => document.getElementById("fileInput").click()}
                    >
                      <i className="fas fa-plus-circle me-2" style={{ color: '#888' }}></i>
                      Choose File
                      {fileData && (
                        <i
                          className="fas fa-check-circle"
                          style={{
                            color: 'green',
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            fontSize: '14px',
                          }}
                        ></i>
                      )}
                      <input
                        type="file"
                        id="fileInput"
                        onChange={handleUpload}
                        disabled={isUploading}
                        className="file-input d-none"
                      />
                    </CommonButton>

                    {/* Upload Referral Button */}
                    <CommonButton style={{
                      width: '100%',        
                      maxWidth: '308px',
                    }}
                      onClick={handleUploadButton} disabled={!fileData || isUploading}>
                      <label style={{ cursor: "pointer", color: '#FFFFFF' }}>
                        <i className="fas fa-upload upload-icon me-2"></i> Upload Referral
                      </label>
                    </CommonButton>
                  </div>

                  {/* Success Message */}
                  {/* {isUploading && (
                    <div className="col-12 text-center mt-3">
                      <p style={{ color: 'green' }}>{'Uploading...'}</p>
                    </div>
                  )} */}
                  {isUploading && (
                    <div ref={messageRef} className="d-flex mt-3 mx-auto" style={{ display: "flex", justifyContent: "center", width: "100%" }}> {/* Use ml-auto for Bootstrap 4 */}
                      <Alert severity={"info"} sx={{
                        backgroundColor: "#E6D9F5", color: "#8548D3",
                        '& .MuiAlert-icon': {
                          color: "#8548D3",
                          marginRight: "10px"
                        }
                      }}>
                        {"Uploading..."}
                      </Alert>
                    </div>
                  )}
                  {/* {successFileMessage && (
                    <div className="col-12 text-center mt-3">
                      <p style={{ color: 'green' }}>{successFileMessage}</p>
                    </div>
                  )} */}
                  {successFileMessage && (
                    <div ref={messageRef} className="d-flex mt-3 mx-auto" style={{ display: "flex", justifyContent: "center", width: "100%" }}> {/* Use ml-auto for Bootstrap 4 */}
                      <Alert severity={"info"} sx={{
                        backgroundColor: "#E6D9F5", color: "#8548D3",
                        '& .MuiAlert-icon': {
                          color: "#8548D3",
                          marginRight: "10px"
                        }
                      }}>
                        {successFileMessage}
                      </Alert>
                    </div>
                  )}
                </div>
              </div>
            )}

          </div>
        </TransitionAnimation>
      )}
      {loading && (
        <div className="container page-main1 ">
          <div className="row">
            <Spinner text="Please wait" />
          </div>
        </div>
      )}
    </>
  );
};

export default Appointmentconfirmation;
