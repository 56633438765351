import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/insuranceEligibilityStyle.css"
import CloseIcon from '@mui/icons-material/Close';
import { logPageView } from "../utils/analytics";

const InsuranceEligibilitySuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { success } = location.state || { success: true };

    useEffect(() => {
        logPageView(location.pathname);
    }, [])

    const backToForm = () => {
        navigate('/insurance/insurance-eligibility');
    }

    return (
        <div style={{ backgroundColor: "#fff", minHeight: "100vh", paddingTop: "5vh" }}>
            {success ? (
                <div className="insurance-check-main" style={{ backgroundColor: "#fff" }} >
                    <div className="row form-div col-12 col-sm-10 col-md-8 col-lg-6 text-center">
                        <div className="col-12" style={{ marginBottom: "20px" }}>
                            <h2 className="insurance-check-title">You're covered!</h2>
                            <CloseIcon className="close-icon" onClick={backToForm} />
                        </div>
                        <div className="col-12">
                            <div className="insurance-check-success">
                                <h3 className="insurance-check-success-text">Based on your plan, we expect your insurance to cover 100% of our services and cost you $0 out-of-pocket.</h3>
                                <button className="book-session-button" onClick={() => window.open("https://signup.geteverlong.com", "_blank")}>
                                    Book my first session
                                </button>
                                <p className="insurance-check-success-text" style={{ textAlign: "left" }}>The estimate above reflects what we anticipate for your copay or coinsurance. Please note that actual costs may occasionally differ once your insurance provider processes the claim.<span className="highlighted-text"> In such cases, any remaining balance, including copays, coinsurance, or denials, will be your responsibility.</span></p>
                                <p className="insurance-check-success-text" style={{ textAlign: "left" }}>For more detailed information about your coverage, we recommend contacting your insurance provider by calling the customer service number on the back of your insurance card to confirm your benefits for Medical Nutritional Therapy.</p>
                            </div>
                        </div>
                    </div>
                </div >
            ) : (
                <div className="container insurance-check-main" style={{ backgroundColor: "#fff" }}>
                    <h2 className="insurance-check-title">Eligibility confirmation failed.</h2>
                    <button className="book-session-button-failed" onClick={backToForm}>
                        Go Back to Form
                    </button>
                </div>
            )}
        </div>
    );
};

export default InsuranceEligibilitySuccess;
