import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/welcome.css";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserData,
  createUserData,
  updateUserData,
} from "../redux/action/userAction";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import {
  setOtherInput,
  setShowErrorMessage,
} from "../redux/Slice/welcomeSlice";
import { getHealthConcernList } from "../redux/action/healthConcernsAction";
import { HEALTH_CONCERN_LIST } from "../utils/constants/health-concern";
import { USER_DATA, FIRST_LOAD } from "../utils/constants/user";
import TransitionAnimation from "./Animation/TransitionAnimation";
import CommonButton from "./Button/Button";
import { Alert } from "@mui/material";
import { Circle } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { logPageView } from "../utils/analytics";
const EverlongForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["evuid"]);
  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
  const healthConcersList = useSelector(
    (state) => state.healthConcerns[HEALTH_CONCERN_LIST] || []
  );

  const progressValue = 0;
  const otherInput = useSelector((state) => state.welcome.otherInput);

  const [error, setError] = useState(false);
  const [healthError, setHealthError] = useState(false);
  const [addInfoError, setAddInfoError] = useState(false);
  const [otherError, setOtherError] = useState(false);


  const [selectedHealthConcern, setSelectedOption] = useState({});
  const [selectedSubOption, setSelectedSubOption] = useState({});
  const [combinedOptions, setCombinedOptions] = useState([]);

  const [otherInputValue, setOtherInputValue] = useState("");
  const [focusedField, setFocusedField] = useState(null);
  const location = useLocation();

  const createUser = () => {
    const evuid = uuidv4();
    setCookie("evuid", evuid, {
      path: "/",
      expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 10),
    });
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (!resData.data.success) {
            dispatch(
              createUserData({
                evuid: cookies["evuid"],
                current_step_id: "/",
              })
            );
            dispatch({ type: FIRST_LOAD, payload: false });
          } else if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
          }
          if(firstLoad) {
            logPageView(location.pathname);
          }
        }
      )
    );
  };

  useEffect(() => {
    if (
      !healthConcersList ||
      (Array.isArray(healthConcersList) && healthConcersList.length === 0)
    ) {
      getHealthList();
    }
    if (!cookies["evuid"]) {
      createUser();
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData, healthConcersList]);

  useEffect(() => {
    const options = healthConcersList.flatMap(category => [
      { label: category.label, value: category.value, categoryId: category.id },
      ...category.subOptions.map(sub => ({
        label: sub.label,
        value: sub.value,
        categoryId: category.id,
        isSubcategory: true,
      })),
    ]);

    setCombinedOptions(options);
  }, [healthConcersList]);

  const handleCategoryChange = (selected) => {
    const category = healthConcersList.find(cat => cat.id === selected.categoryId);

    let subOptionId = null;

    if (selected.isSubcategory && category) {
      const subOption = category.subOptions.find(sub =>
        sub.label === selected.label && sub.value === selected.value
      );
      subOptionId = subOption ? subOption.id : null;
    }

    const updatedSpecialty = {
      id: category ? category.id : null,
      label: category ? category.label : null,
      value: category ? category.value : null,
      subOptions: selected.isSubcategory
        ? {
          id: subOptionId,
          label: selected.label,
          value: selected.value,
        }
        : null,
    };

    dispatch(updateUserData({
      specialty: updatedSpecialty,
      evuid: cookies["evuid"],
    }));

    setSelectedOption(updatedSpecialty);
    setError(false);
    setHealthError(false);
  };


  const handleSelectButton = () => {
    if (!selectedHealthConcern.value) {
      setHealthError("Please select a visit reason.");
      return;
    }

    if (!selectedHealthConcern.subOptions) {
      setSelectedSubOption(null);
    }

    setError(false);
    setHealthError(false);
    setAddInfoError(false);
    setOtherError(false);
    navigate("/selectstates");
  };

  const getHealthList = () => {
    dispatch(getHealthConcernList());
  };

  const setUserDataHandler = (uData) => {
    if (uData && uData.specialty) {
      const specialtyId = uData.specialty.id;

      if (specialtyId !== undefined) {
        const findOption = healthConcersList.find(op => op.id === specialtyId);

        if (findOption) {
          setSelectedOption({
            id: findOption.id,
            label: findOption.label,
            value: findOption.value,
          });

          if (uData.specialty.subOptions) {
            const { id: subOptionId, label, value } = uData.specialty.subOptions;

            if (subOptionId) {
              setSelectedOption(prevState => ({
                ...prevState,
                subOptions: {
                  id: subOptionId,
                  label,
                  value,
                },
              }));
            }
          }
        }
      }
    }
  };


  const handleAdditionalInfoChange = (e) => {
    const inputValue = e.target.value;

    setOtherInputValue(e.target.value);
    dispatch(setOtherInput(inputValue));
    setError(false);
    setOtherError(false);
  };

  const handleAdditionalInfoSave = (e) => {
    const inputValue = e.target.value;
    dispatch(setOtherInput(inputValue));
    dispatch(
      updateUserData({
        specialty: {
          id: selectedHealthConcern.id,
          label: selectedHealthConcern.label,
          value: selectedHealthConcern.value,
          otherText: inputValue,
        },
        evuid: cookies["evuid"],
      })
    );
    setError(false);
    setOtherError(false);
  };

  const handleHealthConcernChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedSubOption({});
    dispatch(setOtherInput(""));
    dispatch(setShowErrorMessage(false));
    dispatch(
      updateUserData({
        specialty: {
          id: selectedOption.id,
          label: selectedOption.label,
          value: selectedOption.value,
          subOptions: {},
        },
        evuid: cookies["evuid"],
      })
    );
    setError(false);
    setHealthError(false);
  };

  const handleSubOptionChange = (selectedOption) => {
    setSelectedSubOption(selectedOption);
    dispatch(
      updateUserData({
        specialty: {
          id: selectedHealthConcern.id,
          label: selectedHealthConcern.label,
          value: selectedHealthConcern.value,
          subOptions: {
            id: selectedOption.id,
            label: selectedOption.label,
            value: selectedOption.value,
          },
        },
        evuid: cookies["evuid"],
      })
    );
    setError(false);
    setAddInfoError(false);
  };

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "#79ba39" : "#79ba39",
      height: "50px",
      boxShadow: state.isFocused ? "0 0 0 1px #79ba39" : null,
      "&:hover": {
        borderColor: "#79ba39",
      },
    }),
    option: (baseStyles, state) => {
      return {
        ...baseStyles,
        height: "50px",
        backgroundColor: state.isFocused
          ? "#a5cc7e"
          : state.isSelected
            ? "#79ba39"
            : null,
        "&:hover": {
          borderColor: "#a5cc7e",
          backgroundColor: "#a5cc7e",
        },
        color: state.isFocused || state.isSelected ? "white" : "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        paddingLeft: state.data.isSubcategory ? '20px' : '10px'
      };
    },
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: "black",
    }),
  };

  const getDisplayValue = (selected) => {
    if (selected) {
      const categoryLabel = selected.label;
      const subOptionLabel = selected.subOptions ? selected.subOptions.label : null;

      return subOptionLabel ? `${categoryLabel} - ${subOptionLabel}` : categoryLabel;
    }
    return null;
  };


  return (
    <>
      <Header progressValue={progressValue} />
      <TransitionAnimation>
        <div className="container page-main1 mt-0">
          <div className="row">
            <div className="col-12 mx-auto text-center">
              <h3 className="mt-4 ">Tell us what brings you in today.</h3>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-8 col-9">
            <p className="mt-3 text-center h6">
              Our dietitians are highly specialized. Your selection will pair you with those who are a perfect fit.
            </p>
          </div>
          <div className="col-lg-12 mt-3 col-md-8 col-sm-12 col-10 mx-auto">
            <div className="button-container mt-2">
              {/* <Select
                options={healthConcersList}
                value={selectedHealthConcern}
                onChange={handleHealthConcernChange}
                placeholder="I’m seeking help with…"
                styles={customStyles}
                className={` ${healthError ? "error-select" : ""}`}
              /> */}
              <Select
                options={combinedOptions}
                onChange={handleCategoryChange}
                placeholder="Select a category or subcategory"
                styles={customStyles}
                className={` ${healthError ? "error-select" : ""}`}
                formatOptionLabel={(option) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {option.isSubcategory && (
                      // <span style={{
                      //   display: 'inline-block',
                      //   width: '6px',
                      //   height: '6px',
                      //   backgroundColor: 'black',
                      //   borderRadius: '50%',
                      //   marginRight: '10px',
                      // }}></span>
                      <Circle style={{ marginRight: "8px", fontSize: "6px" }} />
                    )}
                    <span>{option.label}</span>
                  </div>
                )}
                // value={selectedHealthConcern ? {
                //   label: getDisplayValue(selectedHealthConcern),
                //   value: selectedHealthConcern.value
                // } : null}
                value={
                  selectedHealthConcern
                    ? {
                      label: selectedHealthConcern.subOptions
                        ? selectedHealthConcern.subOptions.label
                        : selectedHealthConcern.label,
                      value: selectedHealthConcern.subOptions
                        ? selectedHealthConcern.subOptions.value
                        : selectedHealthConcern.value
                    }
                    : null
                }
              />
              {/* <div style={{ height: '24px' }}> */}
              {/* {healthError && (
                <p className="text-danger mt-2 error-msg" style={{ fontSize: '14px', fontWeight: "400" }}>
                  {healthError}
                </p>
              )} */}
              <div style={{ position: 'relative' }}>
                {healthError && (
                  <p
                    className="err-insurance mx-auto text-danger mt-1 error-msg"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      position: 'absolute',
                      top: '0',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    {healthError}
                  </p>
                )}
              </div>
              {/* </div> */}

              {/* {selectedHealthConcern &&
                selectedHealthConcern.subOptions &&
                selectedHealthConcern.subOptions.length > 0 && (
                  <>
                    <Select
                      options={selectedHealthConcern.subOptions}
                      value={selectedSubOption}
                      onChange={handleSubOptionChange}
                      placeholder="Please specify"
                      styles={customStyles}
                      className={`mt-3 ${addInfoError ? "error-select" : ""}`}
                    />
                    {addInfoError && <p className="text-danger mt-2 error-msg" style={{ fontSize: '14px', fontWeight: "400" }}>{addInfoError}</p>}
                  </>
                )}
              {selectedHealthConcern?.label === "Other" && (
                <>
                  <input
                    type="text"
                    id="otherInput"
                    className={`form-control mt-4 inputbox ${otherError ? "error" : ""
                      } ${focusedField === "otherInput" ? "selected" : ""}`}
                    onChange={handleAdditionalInfoChange}
                    onBlur={handleAdditionalInfoSave}
                    onFocus={() => handleFocus("otherInput")}
                    value={otherInputValue}
                    placeholder="Please specify"
                  />
                  {otherError && <p className="text-danger mt-2 error-msg" style={{ fontSize: '14px', fontWeight: "400" }}>{otherError}</p>}
                </>
              )} */}
            </div>
          </div>

          {error && (
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <Alert
                severity="error"
                className="mt-3"
                style={{ display: "inline-flex" }}
              >
                {error}
              </Alert>
            </div>
          )}
          <div className="col-lg-5 col-md-8 col-sm-12 col-10 mx-auto">

            <div className="mt-5">
              <CommonButton
                isShowArrow={true}
                className={` ${error ? "selected" : ""}`}
                onClick={handleSelectButton}>
                Continue
              </CommonButton>
            </div>
          </div>
        </div>
      </TransitionAnimation>
    </>
  );
};

export default EverlongForm;
