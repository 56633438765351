import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import "../css/insuranceinformation.css";
import "react-phone-number-input/style.css";
import "../css/DietitianScreen.css";
import InputMask from "react-input-mask";
import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";
import {
  setaddressLine1,
  setaddressLine2,
  setcity,
  setzipCode,
  setSameAsPrimary,
} from "../redux/Slice/insuranceinformationSlice";
import CountdownTimer from "./CountdownTimer";
import { differenceInYears } from "date-fns";
import { useCookies } from "react-cookie";
import { getUserData, updateUserData } from "../redux/action/userAction";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { verifyMemberId } from "../redux/action/insurenceProviderAction";
import moment from "moment";
import { getStateList } from "../redux/action/stateAction";
import { STATE_LIST } from "../utils/constants/state";
import Spinner from "./Spinner";
import { isValidPhone } from "../utils/helpers/CommonHelper";
import TransitionAnimation from "./Animation/TransitionAnimation";
import CommonButton from "./Button/Button";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import { logPageView } from "../utils/analytics";
const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];
const primaryPolicyholderOptions = [
  { value: "Self", label: "Patient" },
  { value: "Spouse", label: "Patient’s Spouse" },
  { value: "Child", label: "Patient’s Parent" },
  { value: "Other", label: "Other" },
];

const relationOption = [
  { value: "caregiver", label: "Caregiver" },
  { value: "child", label: "Child" },
  { value: "dependent", label: "Dependent" },
  { value: "family_member", label: "Family Member" },
  { value: "legal_guardian", label: "Legal Guardian" },
  { value: "is_parent", label: "Parent" },
  { value: "spouse", label: "Spouse" },
  { value: "other", label: "Other" },
];

const InsuranceInformation = () => {
  const [cookies] = useCookies(["evuid"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const progressValue = 80;

  const [focusedField, setFocusedField] = useState(null);
  const [error, setError] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(true);
  const [selectedInsurance, setInsurance] = useState({});

  const [titleAddress, setTitleAddress] = useState("");
  const [title2, setTitle2] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedGender, setSelectedGender] = useState("");
  const [sameAsPrimary, setSameAsPrimaryState] = useState(false);
  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
  const initialState = useSelector((state) => state.state[STATE_LIST]);
  const stateInsuranceInfo = useSelector(
    (state) => state?.insuranceinformation
  );

  const [memberErrorCounter, setMemberErrorCounter] = useState(0);
  const [memberIDerror, setMemberIDerror] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [maintitle, setMaintitle] = useState("");
  const [memberID, setMemberID] = useState("");
  const [isSecondaryEnabled, setIsSecondaryEnabled] = useState(false);
  const [secondaryMemberID, setSecondaryMemberID] = useState("");
  const [dob, setDob] = useState(null);
  const [emergencyFirstName, setEmergencyFirstName] = useState("");
  const [emergencyLastName, setEmergencyLastName] = useState("");
  const [emergencyPhone, setEmergencyPhone] = useState("");
  const [relation, setRelation] = useState("");

  const [primaryPolicyHolderFirstName, setPrimaryPolicyHoderFirstName] =
    useState("");
  const [primaryPolicyHolderLastName, setPrimaryPolicyHoderLastName] =
    useState("");
  const [primaryPolicyHolderGender, setPrimaryPolicyHolderGender] = useState(
    {}
  );
  const [primaryPolicyHolderContact, setPrimaryPolicyHoderContact] =
    useState("");
  const [primaryPolicyHolderBirthMonth, setPrimaryPolicyHolderBirthMonth] =
    useState("");
  const [primaryPolicyHolderBirthDay, setPrimaryPolicyHolderBirthDay] =
    useState("");
  const [primaryPolicyHolderBirthYear, setPrimaryPolicyHolderBirthYear] =
    useState("");
  const [primaryHolderAddressLine1, setPrimaryHolderAddressLine1] =
    useState("");
  const [primaryHolderAddressLine2, setPrimaryHolderAddressLine2] =
    useState("");
  const [primaryHolderCity, setPrimaryHolderCity] = useState("");
  const [primaryHolderState, setPrimaryHolderState] = useState("");
  const [primaryHolderZipcode, setPrimaryHolderZipcode] = useState("");
  const [verifiedBCBSCA, setVerifiedBCBSCA] = useState(false);
  const location = useLocation();

  const [
    selectedPrimaryPolicyholderOption,
    setSelectedPrimaryPolicyholderOption,
  ] = useState({});
  // const selectedDateOfBirth = useSelector(state => state.dateOfBirth);
  const [
    primaryPolicyholderFieldsVisible,
    setPrimaryPolicyholderFieldsVisible,
  ] = useState(false);

  const stateList = useMemo(() => {
    return initialState
      ?.map((stData) => ({
        id: stData.id,
        value: stData.code,
        label: stData.name,
        is_support: stData.is_support,
      }))
      ?.sort((a, b) => a.label.localeCompare(b.label));
  }, [initialState]);
  useMemo(() => {
    setSameAsPrimaryState(stateInsuranceInfo?.isSameAsPrimary || false);
  }, [stateInsuranceInfo]);

  const selectedDateOfBirth = useSelector((state) => state.dateOfBirth);
  const currentYear = new Date().getFullYear();

  const handleDateChange = (newValue) => {
    if (newValue && newValue?.$d) {
      const date = moment(newValue?.$d);
      if (!date.isValid()) {
        setPrimaryPolicyHolderBirthDay(null);
        setPrimaryPolicyHolderBirthMonth(null);
        setPrimaryPolicyHolderBirthYear(null);
        return
      }
      let formattedDate = moment(newValue?.$d).format("MM-DD-YYYY");
      setDob(formattedDate);

      // Extract day, month, and year
      const day = moment(newValue?.$d).format("DD");
      const month = moment(newValue?.$d).format("MM");
      const year = moment(newValue?.$d).format("YYYY");
      setPrimaryPolicyHolderBirthDay(day);
      setPrimaryPolicyHolderBirthMonth(month);
      setPrimaryPolicyHolderBirthYear(year);
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          primary_policy_holder: {
            date_of_birth: `${year}-${month}-${day}`,
          },
        })
      );
    }
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/insuranceinformation",
              })
            );

            if (firstLoad && resData.data.result.current_step_id && resData.data.result.current_step_id !== "/insuranceinformation") {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
            window.scrollTo(0, 0);
          } else {
            navigate("/");
          }
          if(firstLoad) {
            logPageView(location.pathname);
          }
        }
      )
    );
  };

  const setUserDataHandler = (uData) => {
    if (uData.policy_holder_type && uData.policy_holder_type.value) {
      setSelectedPrimaryPolicyholderOption(uData.policy_holder_type);
    } else {
      setSelectedPrimaryPolicyholderOption({});
    }

    if (uData.insurence && uData.insurence.insurance_provider_id) {
      setInsurance({
        value: uData.insurence.insurance_provider_id,
        label: uData.insurence.insurance_provider_name,
      });
      if(uData.insurence.medicare_option) {
        setIsSecondaryEnabled(uData.insurence.medicare_option);
      }
    } else {
      setInsurance({});
    }

    if (uData.patient_gender) {
      setSelectedGender(uData.patient_gender);
    } else {
      setSelectedGender("");
    }
    if (uData.patient_address && uData.patient_address.patient_address_line_1) {
      setAddressLine1(uData.patient_address.patient_address_line_1);
    } else {
      setAddressLine1("");
    }

    if (uData.patient_address && uData.patient_address.patient_address_line_2) {
      setAddress2(uData.patient_address.patient_address_line_2);
    } else {
      setAddress2("");
    }

    if (uData.patient_address && uData.patient_address.patient_city) {
      setCity(uData.patient_address.patient_city);
    } else {
      setCity("");
    }

    if (uData.patient_address && uData.patient_address.patient_state) {
      setState(uData.patient_address.patient_state);
    } else {
      setState("");
    }

    if (uData.patient_address && uData.patient_address.patient_zipcode) {
      setZipCode(uData.patient_address.patient_zipcode);
    } else {
      setZipCode("");
    }

    if (uData.emergency_address && uData.emergency_address.relationship) {
      setRelation(uData.emergency_address.relationship);
    } else {
      setRelation("");
    }

    if (uData.emergency_address && uData.emergency_address.contact_number) {
      setEmergencyPhone(uData.emergency_address.contact_number);
    } else {
      setEmergencyPhone("");
    }

    if (uData.emergency_address && uData.emergency_address.first_name) {
      setEmergencyFirstName(uData.emergency_address.first_name);
    } else {
      setEmergencyFirstName("");
    }

    if (uData.emergency_address && uData.emergency_address.last_name) {
      setEmergencyLastName(uData.emergency_address.last_name);
    } else {
      setEmergencyLastName("");
    }

    if (uData.primary_policy_holder && uData.primary_policy_holder.first_name) {
      setPrimaryPolicyHoderFirstName(uData.primary_policy_holder.first_name);
    } else {
      setPrimaryPolicyHoderFirstName("");
    }

    if (uData.primary_policy_holder && uData.primary_policy_holder.last_name) {
      setPrimaryPolicyHoderLastName(uData.primary_policy_holder.last_name);
    } else {
      setPrimaryPolicyHoderLastName("");
    }

    if (uData.primary_policy_holder && uData.primary_policy_holder.gender) {
      setPrimaryPolicyHolderGender(uData.primary_policy_holder.gender);
    } else {
      setPrimaryPolicyHolderGender("");
    }

    if (
      uData.primary_policy_holder &&
      uData.primary_policy_holder.address_line_1
    ) {
      setPrimaryHolderAddressLine1(uData.primary_policy_holder.address_line_1);
    } else {
      setPrimaryHolderAddressLine1("");
    }

    if (
      uData.primary_policy_holder &&
      uData.primary_policy_holder.address_line_2
    ) {
      setPrimaryHolderAddressLine2(uData.primary_policy_holder.address_line_2);
    } else {
      setPrimaryHolderAddressLine2("");
    }

    if (uData.primary_policy_holder && uData.primary_policy_holder.city) {
      setPrimaryHolderCity(uData.primary_policy_holder.city);
    } else {
      setPrimaryHolderCity("");
    }

    if (uData.primary_policy_holder && uData.primary_policy_holder.state) {
      setPrimaryHolderState(uData.primary_policy_holder.state);
    } else {
      setPrimaryHolderState("");
    }

    if (uData.primary_policy_holder && uData.primary_policy_holder.zipcode) {
      setPrimaryHolderZipcode(uData.primary_policy_holder.zipcode);
    } else {
      setPrimaryHolderZipcode("");
    }

    if (
      uData.primary_policy_holder &&
      uData.primary_policy_holder.contact_number
    ) {
      setPrimaryPolicyHoderContact(uData.primary_policy_holder.contact_number);
    } else {
      setPrimaryPolicyHoderContact("");
    }

    if (uData && uData.member_id) {
      setMemberID(uData.member_id);
    } else {
      setMemberID("");
    }

    if (uData && uData.secondary_member_id) {
      setSecondaryMemberID(uData.secondary_member_id);
    } else {
      setSecondaryMemberID("");
    }

    if (
      uData.primary_policy_holder &&
      uData.primary_policy_holder.date_of_birth
    ) {
      const dateOfBirth = new Date(uData.primary_policy_holder.date_of_birth);
      const mm = String(dateOfBirth.getUTCMonth() + 1).padStart(2, '0');
      const dd = String(dateOfBirth.getUTCDate()).padStart(2, '0');
      const YYYY = dateOfBirth.getUTCFullYear();
      setPrimaryPolicyHolderBirthDay(dd);
      setPrimaryPolicyHolderBirthMonth(mm);
      setPrimaryPolicyHolderBirthYear(YYYY);
      setDob(`${mm}-${dd}-${YYYY}`);
    } else if (uData.date_of_birth) {
      const dateOfBirth = new Date(uData.date_of_birth);
      const mm = String(dateOfBirth.getUTCMonth() + 1).padStart(2, '0');
      const dd = String(dateOfBirth.getUTCDate()).padStart(2, '0');
      const YYYY = dateOfBirth.getUTCFullYear();
      setSelectedDay(dd);
      setSelectedMonth(mm);
      setSelectedYear(YYYY);
    }
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      dispatch(getStateList());
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  useEffect(() => {
    if (
      !selectedPrimaryPolicyholderOption ||
      selectedPrimaryPolicyholderOption.value === "Self"
    ) {
      setPrimaryPolicyholderFieldsVisible(false);
    } else {
      setPrimaryPolicyholderFieldsVisible(true);
    }
  }, [selectedPrimaryPolicyholderOption]);

  const calculateAge = () => {
    if (selectedYear && selectedMonth && selectedDay) {
      const selectedDate = new Date(
        selectedYear,
        selectedMonth - 1,
        selectedDay
      );
      const age = differenceInYears(new Date(), selectedDate);
      return age;
    }
    return -1; // Return a negative value if the date is not fully selected
  };

  const calculatePrimaryPolicyAge = () => {
    if (primaryPolicyHolderBirthYear && primaryPolicyHolderBirthMonth && primaryPolicyHolderBirthDay) {
      const selectedDate = new Date(
        primaryPolicyHolderBirthYear,
        primaryPolicyHolderBirthMonth - 1,
        primaryPolicyHolderBirthDay
      );
      const age = differenceInYears(new Date(), selectedDate);
      return age;
    }
    return -1; // Return a negative value if the date is not fully selected
  };

  useEffect(() => {
    const age = calculateAge();
    if (age >= 10 && age <= 18) {
      setTitleAddress("What is the patient’s address?");
      setTitle2("What is the patient’s birth sex?");
    } else if (age >= 18) {
      setTitleAddress("What is your address?");
      setTitle2("What is your birth sex?");
    }
  }, [selectedYear, selectedMonth, selectedDay]);

  const handleAddressLine1Change = (e) => {
    dispatch(setaddressLine1(e.target.value));
    setAddressLine1(e.target.value);
    setError(false);
  };

  const handlePrimaryPolicyholderChange = (selectedOption) => {
    setSelectedPrimaryPolicyholderOption(selectedOption);
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        policy_holder_type: selectedOption,
      })
    );
    setError(false);
  };

  const handleAddressLine2Change = (e) => {
    dispatch(setaddressLine2(e.target.value));
    setAddress2(e.target.value);
    setError(false);
  };

  const handlePrimaryHolderAddressLine1Change = (e) => {
    setPrimaryHolderAddressLine1(e.target.value);
    setError(false);
  };

  const handlePrimaryHolderAddressLine2Change = (e) => {
    setPrimaryHolderAddressLine2(e.target.value);
    setError(false);
  };

  const handleCityChange = (e) => {
    dispatch(setcity(e.target.value));

    setCity(e.target.value);
    setError(false);
  };

  const handlePrimaryCityChange = (e) => {
    setPrimaryHolderCity(e.target.value);
    setError(false);
  };

  const handleStateChange = (stData, name) => {
    setState(stData?.label);
    if (name) {
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          [name]: stData?.label,
        })
      );
    }

    setError(false);
  };

  const handleZipCodeChange = (e) => {
    dispatch(setzipCode(e.target.value));
    setZipCode(e.target.value);
    setError(false);
  };

  const handlePrimaryZipCodeChange = (e) => {
    setPrimaryHolderZipcode(e.target.value);
    setError(false);
  };



  const handleGenderChange = (selectedOption) => {
    setSelectedGender(selectedOption.value);
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        patient_gender: selectedOption.value,
      })
    );
    setError(false);
  };

  const handleBirthSexChange = (selectedOption) => {
    setPrimaryPolicyHolderGender(selectedOption.value);
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        primary_policy_holder: {
          gender: selectedOption.value,
        },
      })
    );
    setError(false);
  };

  const handlememberID1Change = (e) => {
    setMemberID(e.target.value);
    setError(false);
  };

  const handlememberID2Change = (e) => {
    setSecondaryMemberID(e.target.value);
    setError(false);
  };

  const handleFirstNameChange = (e) => {
    setEmergencyFirstName(e.target.value);
    setError(false);
  };
  const handleFirstNamePrimaryChange = (e) => {
    setPrimaryPolicyHoderFirstName(e.target.value);
    setError(false);
  };

  const handleLastNamePrimaryChange = (e) => {
    setPrimaryPolicyHoderLastName(e.target.value);
    setError(false);
  };

  const handleLastNameChange = (e) => {
    setEmergencyLastName(e.target.value);
    setError(false);
  };
  const handlePhoneChange = (e) => {
    setEmergencyPhone(e.target.value);
    setError(false);
  };
  const handlePrimaryPhoneChange = (e) => {
    setPrimaryPolicyHoderContact(e.target.value);
    setError(false);
  };

  const onInputPhoneChange = (e) => {
    let phoneNumber = e.target.value;
    const regex = /^\d{10}$/;
    if (regex.test(phoneNumber) && phoneNumber?.length >= 10) {
      phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
      return setEmergencyPhone(phoneNumber);
    }
  };

  const onInputPrimaryPhoneChange = (e) => {
    let phoneNumber = e.target.value;
    const regex = /^\d{10}$/;
    if (regex.test(phoneNumber) && phoneNumber?.length >= 10) {
      phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
      return setPrimaryPolicyHoderContact(phoneNumber);
    }
  };

  const handleChangeRelation = (value, name) => {
    setRelation(value?.value);
    if (name) {
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          [name]: value?.value,
        })
      );
    }
    setError(false);
  };

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };
  const handleCheckboxChange = (e) => {
    setSameAsPrimaryState(e.target.checked);
    dispatch(setSameAsPrimary(e.target.checked));
    if (e?.target?.checked === true) {
      dispatch(setaddressLine1(primaryHolderAddressLine1));
      setAddressLine1(primaryHolderAddressLine1);
      dispatch(setaddressLine2(primaryHolderAddressLine2));
      setAddress2(primaryHolderAddressLine2);
      dispatch(setcity(primaryHolderCity));
      setCity(primaryHolderCity);
      setState(primaryHolderState);
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          patient_state: primaryHolderState,
        })
      );
      dispatch(setzipCode(primaryHolderZipcode));
      setZipCode(primaryHolderZipcode);
    } else {
      dispatch(setaddressLine1(""));
      setAddressLine1("");
      dispatch(setaddressLine2(""));
      setAddress2("");
      dispatch(setcity(""));
      setCity("");
      setState("");
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          patient_state: "",
        })
      );
      dispatch(setzipCode(""));
      setZipCode("");
    }
  };

  const handleBlur = (e) => {
    if (
      e.target.name &&
      [
        "patient_address_line_1",
        "patient_address_line_2",
        "patient_city",
        "patient_state",
        "patient_zipcode",
        "emergency_address.first_name",
        "emergency_address.last_name",
        "emergency_address.contact_number",
        "emergency_address.relationship",
        "member_id",
        "secondary_member_id",
      ].includes(e.target.name)
    ) {
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          [e.target.name]: e.target.value,
        })
      );
    }

    setFocusedField(null);
    setError(false);
  };

  const handlePrimaryBlur = (e) => {
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        primary_policy_holder: {
          [e.target.name]: e.target.value,
        },
      })
    );
    setFocusedField(null);
    setError(false);
  };

  const handleVerifyMemberId = () => {
    setLoading(true);
    dispatch(
      verifyMemberId(
        {
          evuid: cookies["evuid"],
        },
        (response) => {
          setLoading(false);
          if (response.data.success === true) {
            navigate("/CreditCardInfo");
          } else {
            const showError = (message, isShowContinueButton) => {
              setMemberIDerror(message);
              navigateToBottom();
              setShowContinueButton(isShowContinueButton);
            };
            if ((response.data.isActivePlan || false) === true) {
              showError(
                response.data.message
                , response.data.isShowButton || false);
            } else {
              if (memberID && memberErrorCounter <= 2) {
                let eCounter = memberErrorCounter + 1;
                setMemberErrorCounter(eCounter);

                if (eCounter === 3) {
                  setShowContinueButton(false);
                  return setMemberIDerror(
                    "Unfortunately, your insurance information still could not be verified. Please contact your insurance company to confirm your plan details."
                  );
                }
                showError(
                  response.data.message
                  , response.data.isShowButton || false);
                navigateToBottom();
                return;
              } else {
                navigate("/insuranceinformation2");
              }
            }
          }
        }
      )
    );
  };

  function navigateToBottom() {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth", // Optional, adds smooth scrolling animation
      });
    }, 500);
  }

  const handleSelectButton = () => {
    if (selectedPrimaryPolicyholderOption.value) {
    } else {
    }

    if (selectedInsurance.value === "97214" && isSecondaryEnabled) {
      if (!secondaryMemberID) {
        setError("Please complete all required fields.");
        return;
      }
    }

    if (!selectedGender) {
      setError("Please complete all required fields.");
      return;
    }
    if (
      !(
        selectedInsurance &&
        (selectedInsurance.label === "Private Pay" ||
          selectedInsurance.label === "HSA / FSA")
      )
    ) {
      if (!memberID || (memberID && memberID.trim() === "")) {
        setError("Please complete all required fields.");
        return;
      }
    }
    if (!addressLine1 || (addressLine1 && addressLine1.trim() === "")) {
      setError("Please complete all required fields.");
      return;
    }

    if (!city.trim()) {
      setError("Please complete all required fields.");
      return;
    }
    if (!state.trim()) {
      setError("Please complete all required fields.");
      return;
    }
    if (!zipcode) {
      setError("Please complete all required fields.");
      return;
    }

    if (!emergencyFirstName.trim()) {
      setError("Please complete all required fields.");
      return;
    }
    if (!emergencyLastName.trim()) {
      setError("Please complete all required fields.");
      return;
    }

    if (!isValidPhone(emergencyPhone)) {
      setError("Please complete all required fields.");
      return;
    }
    if (!relation.trim()) {
      setError("Please complete all required fields.");
      return;
    }

    if (
      !(
        selectedInsurance &&
        (selectedInsurance.label === "Private Pay" ||
          selectedInsurance.label === "HSA / FSA")
      )
    ) {
      if (
        selectedPrimaryPolicyholderOption &&
        selectedPrimaryPolicyholderOption.value !== "Self"
      ) {
        if (
          !primaryPolicyHolderFirstName ||
          (primaryPolicyHolderFirstName && !primaryPolicyHolderFirstName.trim())
        ) {
          setError("Please complete all required fields.");
          return;
        }

        if (
          !primaryPolicyHolderLastName ||
          (primaryPolicyHolderLastName && !primaryPolicyHolderLastName.trim())
        ) {
          setError("Please complete all required fields.");
          return;
        }

        if (
          !primaryPolicyHolderGender ||
          (primaryPolicyHolderGender && !primaryPolicyHolderGender.trim())
        ) {
          setError("Please complete all required fields.");
          return;
        }

        if (!isValidPhone(primaryPolicyHolderContact)) {
          setError("Please complete all required fields.");
          return;
        }

        if (!dob) {
          setError("Please complete all required fields.");
          return
        } else {
          const isValidFormat = dayjs(dob, 'MM-DD-YYYY', true).isValid();
          if (dob && !isValidFormat) {
            setError("Please complete all required fields.");
            return
          }
          let age = calculatePrimaryPolicyAge();
          if (age < 0 || age >= 130) {
            setError("Please complete all required fields.");
            return
          }
        }
      }
    }

    if (
      selectedInsurance &&
      (selectedInsurance.label === "Private Pay" ||
        selectedInsurance.label === "HSA / FSA") ||
      verifiedBCBSCA
    ) {
      navigate("/CreditCardInfo");
    } else {
      handleVerifyMemberId();
    }
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        patient_address_line_1: addressLine1,
        patient_address_line_2: addressLine2,
        patient_city: city,
        patient_state: state,
        patient_zipcode: zipcode,
        "emergency_address.first_name": emergencyFirstName,
        "emergency_address.last_name": emergencyLastName,
        "emergency_address.contact_number": emergencyPhone,
        "emergency_address.relationship": relation,
        primary_policy_holder: {
          first_name: primaryPolicyHolderFirstName,
          last_name: primaryPolicyHolderLastName,
          gender: primaryPolicyHolderGender,
          address_line_1: primaryHolderAddressLine1,
          address_line_2: primaryHolderAddressLine2,
          city: primaryHolderCity,
          state: primaryHolderState,
          zipcode: primaryHolderZipcode,
          contact_number: primaryPolicyHolderContact,
        },
        member_id: memberID,
        secondary_member_id: (selectedInsurance.value === "97214" && isSecondaryEnabled) ? secondaryMemberID : null,
      })
    );
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "#79ba39" : "#cecece",
      height: "52px",
      boxShadow: state.isFocused ? "0 0 0 1px #79ba39" : null,
      "&:hover": {
        borderColor: "#79ba39",
      },
    }),
    option: (baseStyles, state) => {
      return {
        ...baseStyles,
        height: "50px",
        backgroundColor: state.isFocused
          ? "#a5cc7e"
          : state.isSelected
            ? "#79ba39"
            : null,
        "&:hover": {
          borderColor: "#a5cc7e",
          backgroundColor: "#a5cc7e",
        },
        color: state.isFocused || state.isSelected ? "white" : "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      };
    },
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: "black",
    }),
  };

  useEffect(() => {
    if (selectedDateOfBirth && selectedDateOfBirth.month) {
      setSelectedMonth(selectedDateOfBirth.month);
    }
    if (selectedDateOfBirth && selectedDateOfBirth.year) {
      setSelectedYear(selectedDateOfBirth.year);
    }
    if (selectedDateOfBirth && selectedDateOfBirth.day) {
      setSelectedDay(selectedDateOfBirth.day);
    }
  }, [selectedDateOfBirth]);

  useEffect(() => {
    if (
      selectedInsurance &&
      (selectedInsurance.label === "Private Pay" ||
        selectedInsurance.label === "HSA / FSA")
    ) {
      setMaintitle("Enter the rest of your information");
    } else {
      setMaintitle("Enter your insurance details.");
    }
  }, [selectedInsurance]);

  const handleDobBlur = (event) => {
    setFocusedField(null);
    // Extract the new date value from the event target
    const newValue = event.target.value;
    if (newValue) {
      const date = moment(newValue, "MM/DD/YYYY"); // Assuming the date input uses 'YYYY-MM-DD' format

      // Check if the date is valid
      if (date.isValid()) {
        const day = date.format("DD");
        const month = date.format("MM");
        const year = date.format("YYYY");

        setPrimaryPolicyHolderBirthDay(day);
        setPrimaryPolicyHolderBirthMonth(month);
        setPrimaryPolicyHolderBirthYear(year);
        setDob(`${month}-${day}-${year}`);
      } else {
        setPrimaryPolicyHolderBirthDay(null);
        setPrimaryPolicyHolderBirthMonth(null);
        setPrimaryPolicyHolderBirthYear(null);
        setDob(null);
      }
    }

  }

  return (
    <>
      {!loading && (
        <>
          <Header progressValue={progressValue} styles={{ position: "relative" }} />
          <div className="timer-top-right">
            <CountdownTimer />
          </div>
          <TransitionAnimation>
            <div className="container InsuranceInformation">
              <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-12 text-left mx-auto mt-5">
                  <h3 className="text-center">{maintitle}</h3>

                  {selectedInsurance &&
                    (selectedInsurance.label === "Private Pay" ||
                      selectedInsurance.label === "HSA / FSA") ? null : (
                    <div className="row mt-4">
                      <div className="col-md-12 mt-4 mb-3">
                        <label htmlFor="primaryPolicyholder" className="h4">
                          Primary Policyholder
                        </label>
                        <Select
                          options={primaryPolicyholderOptions}
                          value={selectedPrimaryPolicyholderOption}
                          onChange={handlePrimaryPolicyholderChange}
                          styles={customStyles}
                          className={`${error && !selectedPrimaryPolicyholderOption.value
                            ? "error-select"
                            : ""
                            }`}
                        />
                      </div>
                    </div>
                  )}

                  {primaryPolicyholderFieldsVisible &&
                    selectedPrimaryPolicyholderOption.value &&
                    !(
                      selectedInsurance &&
                      (selectedInsurance.label === "Private Pay" ||
                        selectedInsurance.label === "HSA / FSA")
                    ) && (
                      <div className="mt-1">
                        <div className="row mt-4">
                          <div
                            className={`col-md-6  mb-3 ${error && !primaryPolicyHolderFirstName
                              ? "error"
                              : ""
                              }`}>
                            <label htmlFor="firstnameprimary">
                              First Name of Primary Policyholder
                            </label>
                            <input
                              type="text"
                              id="firstnameprimary"
                              className={`form-control inputbox mt-2 ${focusedField === "firstnameprimary"
                                ? "selected"
                                : ""
                                }`}
                              value={primaryPolicyHolderFirstName}
                              name="first_name"
                              onInput={handleFirstNamePrimaryChange}
                              onFocus={() => handleFocus("firstnameprimary")}
                              onBlur={handlePrimaryBlur}
                            // autoComplete="off"
                            />
                          </div>
                          <div
                            className={`col-md-6 mb-3 ${error && !primaryPolicyHolderLastName
                              ? "error"
                              : ""
                              }`}>
                            <label htmlFor="lastnameprimary">
                              Last Name of Primary Policyholder
                            </label>
                            <input
                              type="text"
                              id="lastnameprimary"
                              className={`form-control inputbox  mt-2 ${focusedField === "lastnameprimary"
                                ? "selected"
                                : ""
                                }`}
                              value={primaryPolicyHolderLastName}
                              name="last_name"
                              onInput={handleLastNamePrimaryChange}
                              onFocus={() => handleFocus("lastnameprimary")}
                              onBlur={handlePrimaryBlur}
                            // autoComplete="off"
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 text-left mx-auto ">
                            <div className="row div-align-birth">
                              <div className="col-md-6 mb-3">
                                <p className="f-16 mb-2">
                                  Birth Sex of Primary Policyholder
                                </p>
                                <div className="col-md-12 ">
                                  <Select
                                    id="birthSelect"
                                    options={genderOptions}
                                    value={genderOptions.find(
                                      (option) =>
                                        option.value ===
                                        primaryPolicyHolderGender
                                    )}
                                    onChange={handleBirthSexChange}
                                    styles={customStyles}
                                    className={`${error && !primaryPolicyHolderGender
                                      ? "error-select"
                                      : ""
                                      }`}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div
                                  className={`col-md-12 mb-3 ${error &&
                                    !isValidPhone(primaryPolicyHolderContact)
                                    ? "error"
                                    : ""
                                    }`}>
                                  <label
                                    className="mb-2"
                                    htmlFor="primaryphone">
                                    Phone Number of Primary Policyholder
                                  </label>
                                  <InputMask
                                    mask="(999) 999-9999"
                                    value={primaryPolicyHolderContact}
                                    onInput={onInputPrimaryPhoneChange}
                                    onChange={handlePrimaryPhoneChange}
                                    onFocus={() => handleFocus("primaryphone")}
                                    onBlur={handlePrimaryBlur}
                                    name="contact_number"
                                    className={`form-control inputbox primaryphone ${focusedField === "primaryphone"
                                      ? "selected"
                                      : ""
                                      }`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 text-left">
                              <label htmlFor="gender" className="mb-2">
                                Date of Birth of Primary Policyholder
                              </label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateField
                                  id="dob"
                                  name="dob"
                                  value={dob ? dayjs(dob, "MM-DD-YYYY") : null}
                                  className={`form-control inputbox`}
                                  onInput={(e) => setDob(e.target.value)}
                                  onChange={(newValue) => handleDateChange(newValue)}
                                  onFocus={() => handleFocus("dob")}
                                  onBlur={handleDobBlur}
                                  placeholder="MM/DD/YYYY"
                                  sx={{
                                    width: "103%",
                                    "& .MuiInputBase-root": {
                                      border: (error && (calculatePrimaryPolicyAge() < 0 || calculatePrimaryPolicyAge() >= 130)) ? "1px solid red" : "1px solid #dee2e6",
                                      borderRadius: "0.3rem",
                                      display: "block",
                                      // width: "100%",
                                      backgroundColor: "white",
                                      boxShadow: "none",
                                      outline: "none",
                                      letterSpacing: "0.01rem",
                                      "&:hover": {
                                        // borderColor: "#79ba39"
                                      },
                                      "&.Mui-focused": {
                                        border: "2px solid #7ABA39",
                                      },
                                      height: "54px",
                                    },
                                    "& .MuiInputBase-input": {
                                      lineHeight: "2.5",
                                      padding: "0.9rem 0.60rem",
                                      fontSize: "1rem",
                                      marginLeft: "1px",
                                      color: "#000",
                                      "&:hover": {
                                        border: "none",
                                      },
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    }
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div className="mt-5">
                            <div
                              className={`col-md-12 mb-3 ${error && !primaryHolderAddressLine1
                                ? "error"
                                : ""
                                }`}>
                              <p className="mt-4 mb-1 w-60 h4 ">
                                Address of Primary Policyholder
                              </p>
                              <label
                                htmlFor="primaryaddressLine1"
                                className="mt-3">
                                Address Line 1
                              </label>
                              <input
                                type="text"
                                id="primaryaddressLine1"
                                className={`form-control inputbox mt-2 ${focusedField === "primaryaddressLine1"
                                  ? "selected"
                                  : ""
                                  }`}
                                value={primaryHolderAddressLine1}
                                onBlur={handlePrimaryBlur}
                                name="address_line_1"
                                // autoComplete="off"
                                onInput={handlePrimaryHolderAddressLine1Change}
                                onFocus={() =>
                                  handleFocus("primaryaddressLine1")
                                }
                              />
                            </div>
                            <div className={`col-md-12 mb-3`}>
                              <label htmlFor="primaryaddressLine2">
                                Address Line 2
                              </label>
                              <input
                                type="text"
                                id="primaryaddressLine2"
                                className={`form-control inputbox mt-2 ${focusedField === "primaryaddressLine2"
                                  ? "selected"
                                  : ""
                                  }`}
                                value={primaryHolderAddressLine2}
                                onInput={handlePrimaryHolderAddressLine2Change}
                                onFocus={() =>
                                  handleFocus("primaryaddressLine2")
                                }
                                onBlur={handlePrimaryBlur}
                                name="address_line_2"
                              // autoComplete="off"
                              />
                            </div>
                            <div className="row">
                              <div
                                className={`col-md-6 mb-3 ${error && !primaryHolderCity ? "error" : ""
                                  }`}>
                                <label htmlFor="primarycity">City</label>
                                <input
                                  type="text"
                                  id="primarycity"
                                  className={`form-control inputbox  mt-2 ${focusedField === "primarycity"
                                    ? "selected"
                                    : ""
                                    }`}
                                  value={primaryHolderCity}
                                  onInput={handlePrimaryCityChange}
                                  onFocus={() => handleFocus("primarycity")}
                                  onBlur={handlePrimaryBlur}
                                  name="city"
                                // autoComplete="off"
                                />
                              </div>

                              <div
                                className={`col-md-3 mb-3 ${error && !primaryHolderState ? "error" : ""
                                  }`}>
                                <label htmlFor="primarystate">State</label>
                                { }
                                <Select
                                  options={stateList}
                                  placeholder={primaryHolderState || ""}
                                  name="state"
                                  value={stateList.find(
                                    (st) => st.label === primaryHolderState
                                  )}
                                  onChange={(dt) => {
                                    setPrimaryHolderState(dt.label);
                                    handlePrimaryBlur({
                                      target: {
                                        value: dt.label,
                                        name: "state",
                                      },
                                    });
                                  }}
                                  isSearchable
                                  noOptionsMessage={() =>
                                    "No state is available"
                                  }
                                  styles={customStyles}
                                  className={`mt-2 ${error && !primaryHolderState
                                    ? "error-select"
                                    : ""
                                    }`}
                                />
                              </div>
                              <div
                                className={`col-md-3 mb-3 ${error && !primaryHolderZipcode ? "error" : ""
                                  }`}>
                                <label htmlFor="primaryzipcode">Zip Code</label>
                                <input
                                  type="text"
                                  id="primaryzipcode"
                                  className={`form-control inputbox mt-2 ${focusedField === "primaryzipcode"
                                    ? "selected"
                                    : ""
                                    }`}
                                  value={primaryHolderZipcode}
                                  onInput={handlePrimaryZipCodeChange}
                                  onFocus={() => handleFocus("primaryzipcode")}
                                  onBlur={handlePrimaryBlur}
                                  name="zipcode"
                                // autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {selectedInsurance &&
                    (selectedInsurance.label === "Private Pay" ||
                      selectedInsurance.label === "HSA / FSA") ? null : (
                    <div className="row mt-4">
                      <div
                        className={`col-md-12 mb-3 ${error && !memberID ? "error" : ""
                          }`}>
                        <label htmlFor="memberID" className="h4 mt-4">
                          Member ID
                        </label>
                        <p>
                          Please enter the Member ID exactly as it appears on
                          the insurance card, including any prefixes, to avoid
                          mistakes with insurance coverage.
                        </p>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <input
                            type="text"
                            id="memberID"
                            placeholder={(selectedInsurance.value === "97214" && isSecondaryEnabled) ? "Primary" : "Member ID"}
                            name="member_id"
                            className={`form-control inputbox ${focusedField === "memberID" ? "selected" : ""}`}
                            value={memberID}
                            onInput={handlememberID1Change}
                            onFocus={() => handleFocus("memberID")}
                            onBlur={handleBlur}
                            style={{
                              width: (selectedInsurance.value === "97214" && isSecondaryEnabled) ? "calc(50% - 4px)" : "100%",
                              marginRight: (selectedInsurance.value === "97214" && isSecondaryEnabled) ? "8px" : "0",
                            }}
                            autoComplete="off"
                          />
                          {(selectedInsurance.value === "97214" && isSecondaryEnabled) && (
                            <input
                              type="text"
                              id="secondaryMemberID"
                              name="secondary_member_id"
                              placeholder="Secondary/Supplemental"
                              className={`form-control inputbox ${focusedField === "secondaryMemberID" ? "selected" : ""}`}
                              value={secondaryMemberID}
                              onInput={handlememberID2Change}
                              onFocus={() => handleFocus("secondaryMemberID")}
                              onBlur={handleBlur}
                              style={{ width: "calc(50% - 4px)", marginLeft: "8px" }}
                              autoComplete="off"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row mt-4">
                    <div className="col-lg-12 col-md-12 col-sm-12 text-left mx-auto mt-4">
                      <div className="col-md-12">
                        <label htmlFor="gender" className="h4">
                          {title2}
                        </label>
                        <Select
                          options={genderOptions}
                          value={genderOptions.find(
                            (option) => option.value === selectedGender
                          )}
                          onChange={handleGenderChange}
                          styles={customStyles} // Apply your custom styles here
                          className={`${error && !selectedGender ? "error-select" : ""
                            }`}
                        />
                      </div>
                    </div>
                  </div>
                  {/* ... */}

                  {/* .... */}
                  <div className="row mt-5">
                    <div className="col-md-12 ">
                      <p className="mt-4 w-60 h4 mb-0 ">{titleAddress}</p>
                    </div>
                  </div>
                  {selectedPrimaryPolicyholderOption.value &&
                    selectedPrimaryPolicyholderOption.value !== "Self" && (
                      <div className="row mt-3">
                        <div className="d-flex align-items-center">
                          <label className="checkbox-label">
                            Same as Primary Policyholder
                          </label>
                          <input
                            type="checkbox"
                            className="check-same"
                            checked={sameAsPrimary}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                      </div>
                    )}

                  <div className="row">
                    <div
                      className={`col-md-12 mb-3 ${error && !addressLine1 ? "error" : ""
                        }`}>
                      <label htmlFor="addressLine1" className="mt-3">
                        Address Line 1
                      </label>
                      <input
                        type="text"
                        id="addressLine1"
                        className={`form-control inputbox  mt-2 ${focusedField === "addressLine1" ? "selected" : ""
                          }`}
                        value={addressLine1}
                        name="patient_address_line_1"
                        onInput={handleAddressLine1Change}
                        onFocus={() => handleFocus("addressLine1")}
                        onBlur={handleBlur}
                      // autoComplete="off"
                      />
                    </div>
                    <div className={`col-md-12 mb-3`}>
                      <label htmlFor="addressLine2">Address Line 2</label>
                      <input
                        type="text"
                        id="addressLine2"
                        className={`form-control inputbox  mt-2 ${focusedField === "addressLine2" ? "selected" : ""
                          }`}
                        value={addressLine2}
                        name="patient_address_line_2"
                        onInput={handleAddressLine2Change}
                        onFocus={() => handleFocus("addressLine2")}
                        onBlur={handleBlur}
                      // autoComplete="off"
                      />
                    </div>

                    <div
                      className={`col-md-5 mb-3 ${error && !city ? "error" : ""
                        }`}>
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        id="city"
                        className={`form-control inputbox mt-2 ${focusedField === "city" ? "selected" : ""
                          }`}
                        value={city}
                        name="patient_city"
                        onInput={handleCityChange}
                        onFocus={() => handleFocus("city")}
                        onBlur={handleBlur}
                      // autoComplete="off"
                      />
                    </div>

                    <div
                      className={`col-md-4 mb-3 ${error && !state ? "error" : ""
                        }`}>
                      <label htmlFor="state">State</label>

                      <Select
                        options={stateList}
                        placeholder={state || ""}
                        name="patient_state"
                        value={stateList.find((st) => st.label === state)}
                        onChange={(dt) =>
                          handleStateChange(dt, "patient_state")
                        }
                        isSearchable
                        noOptionsMessage={() => "No state is available"}
                        styles={customStyles}
                        className={`mt-2 ${error && !state ? "error-select" : ""
                          }`}
                      />
                    </div>
                    <div
                      className={`col-md-3 ${error && !zipcode ? "error" : ""
                        }`}>
                      <label htmlFor="zipcode">Zip Code</label>
                      <input
                        type="text"
                        id="zipcode"
                        className={`form-control inputbox  mt-2 ${focusedField === "zipcode" ? "selected" : ""
                          }`}
                        value={zipcode}
                        name="patient_zipcode"
                        onInput={handleZipCodeChange}
                        onFocus={() => handleFocus("zipcode")}
                        onBlur={handleBlur}
                      // autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6 col-md-8 col-sm-12 text-left mx-auto ">
                  <h3 className="mt-4 text-left h4">Emergency Contact</h3>
                  <div className="row mt-3">
                    <div
                      className={`col-md-6 mb-3 ${error && !emergencyFirstName ? "error" : ""
                        }`}>
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        id="firstName"
                        className={`form-control inputbox   mt-2 ${focusedField === "firstName" ? "selected" : ""
                          }`}
                        value={emergencyFirstName}
                        onInput={handleFirstNameChange}
                        name="emergency_address.first_name"
                        onFocus={() => handleFocus("firstName")}
                        onBlur={handleBlur}
                      // autoComplete="off"
                      />
                    </div>
                    <div
                      className={`col-md-6 mb-3 ${error && !emergencyLastName ? "error" : ""
                        }`}>
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        id="lastName"
                        className={`form-control inputbox  mt-2 ${focusedField === "lastName" ? "selected" : ""
                          }`}
                        value={emergencyLastName}
                        onInput={handleLastNameChange}
                        name="emergency_address.last_name"
                        onFocus={() => handleFocus("lastName")}
                        onBlur={handleBlur}
                      // autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3 ">
                      <div
                        className={`col-md-12  ${error && !isValidPhone(emergencyPhone) ? "error" : ""
                          }`}>
                        <label htmlFor="phone">Phone Number</label>
                        <InputMask
                          mask="(999) 999-9999"
                          value={emergencyPhone}
                          onInput={onInputPhoneChange}
                          onChange={handlePhoneChange}
                          name="emergency_address.contact_number"
                          onFocus={() => handleFocus("phone")}
                          onBlur={handleBlur}
                          className={`form-control inputbox phone   mt-2 ${focusedField === "phone" ? "selected" : ""
                            }`}
                        />
                      </div>
                    </div>
                    <div
                      className={`col-md-6 mb-3 ${error && !relation ? "error" : ""
                        }`}>
                      <label htmlFor="email">Relationship</label>

                      <Select
                        options={relationOption}
                        placeholder={relation || ""}
                        name="emergency_address.relationship"
                        value={relationOption.find(
                          (ff) => ff.value === relation
                        )}
                        onChange={(value) =>
                          handleChangeRelation(
                            value,
                            "emergency_address.relationship"
                          )
                        }
                        isSearchable
                        noOptionsMessage={() => "No relation is available"}
                        styles={customStyles}
                        className={`mt-2 ${error && !relation ? "error-select" : ""
                          }`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-8 col-sm-12 text-left mx-auto ">
                {memberIDerror && (
                  <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Alert
                      severity="error"
                      className="mt-3"
                      style={{ display: "inline-flex" }}
                    >
                      {memberIDerror}
                    </Alert>
                  </div>
                )}
                {error && (
                  <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Alert
                      severity="error"
                      // className="mt-3"
                      style={{ display: "inline-flex" }}
                    >
                      {error}
                    </Alert>
                  </div>
                )}
                <div className="mt-1"></div>
                {showContinueButton && (
                  <CommonButton
                    isShowArrow
                    className={`${error ? "selected" : ""}`}
                    onClick={handleSelectButton}>
                    Continue
                  </CommonButton>
                )}
                {/* <CountdownTimer /> */}
              </div>
            </div>
          </TransitionAnimation>
        </>
      )}
      {loading && (
        <div className="container page-main1 ">
          <div className="row">
            <Spinner text="Please wait" />
          </div>
        </div>
      )}
    </>
  );
};

export default InsuranceInformation;
