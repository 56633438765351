import ReactGA from "react-ga4";

// Replace with your Google Analytics Measurement ID
const GA_MEASUREMENT_ID = "G-F32438PFS4";

export const initGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
};

export const logPageView = (page) => {
  ReactGA.send({ hitType: "pageview", page });
};