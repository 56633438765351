import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import "../css/Progressbar.css";
import "../css/welcome.css";

import TransitionAnimation from "./Animation/TransitionAnimation";
import CommonButton from "./Button/Button";
import { useLocation } from "react-router-dom";
import { logPageView } from "../utils/analytics";

const Youreeligible = () => {
  const progressValue = 80;
  const navigate = useNavigate();

  const handleSelectButton = () => {
    navigate("/CreditCardInfo");
  };
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
    window.scrollTo(0, 0);

  }, []);

  return (
    <>
      <Header progressValue={progressValue} />
      <TransitionAnimation>
        <div className="container page-main1 insurance2-margin">
          <div className="col-lg-5 col-md-8 col-sm-12 col-12 mx-auto text-center mx-auto Youreeligible">
            <h3 className="mt-4">
              Your insurance information could not be verified.
            </h3>
            <p className="mt-4">
              Everlong’s services are 100% covered by insurance for 95% of
              patients. If your plan happens to have exclusions for our
              services, you <b>will not</b> be charged for any sessions that
              have already occurred as long as you selected your correct plan
              upon booking (ex. PPO vs HMO). You will only be responsible for
              payment if you have a copay or coinsurance.
            </p>
          </div>
          <div className="mt-3">
            <CommonButton
              isShowArrow
              onClick={handleSelectButton}>
              Continue
            </CommonButton>
          </div>
        </div>
      </TransitionAnimation>
    </>
  );
};

export default Youreeligible;
