import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import Header from "./header";
import "../css/creditcardinfo.css";
import "../css/DietitianScreen.css";

import info from "../images/info-icon.png";
import Spinner from "./Spinner";
import Profile from "../images/pro.png"; // Import the profile image
import { useDispatch, useSelector } from "react-redux";
import {
  setFrontInsuranceCardImageUrl,
  setBackInsuranceCardImageUrl,
  setFrontSecondaryInsuranceCardImageUrl,
  setBackSecondaryInsuranceCardImageUrl,
} from "../redux/Slice/CreditCardInfoSlice";
import CountdownTimer from "./CountdownTimer";
import InsurenceUpload from "./DropZone/InsurenceUpload";
import moment from "moment";
import imageCompression from "browser-image-compression";
import { useCookies } from "react-cookie";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { getUserData, updateUserData } from "../redux/action/userAction";
import {
  getDietitian,
  createPaymentToken,
  updateQuickNotes,
} from "../redux/action/insurenceProviderAction";
import { DIETITIAN_DATA, SLOT_LIST } from "../utils/constants/insurence-provider";
import TransitionAnimation from "./Animation/TransitionAnimation";
import CommonButton from "./Button/Button";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import { logPageView } from "../utils/analytics";

const CreditCardInfo = () => {
  const [cookies, setCookie] = useCookies(["evuid"]);
  const fileInputRef = useRef(null);
  const progressValue = 90;
  const navigate = useNavigate();
  const [focusedField, setFocusedField] = useState(null);
  const [showInfoMessage, setShowInfoMessage] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [appointmentData, setAppointmentData] = useState({});
  const [selectedInsurance, setInsurance] = useState({});
  const [secondaryMemberID, setSecondaryMemberID] = useState(null);
  const location = useLocation();
  const [imageError0, setImageError0] = useState({
    index: 0,
    error: "",
  });
  const [imageError1, setImageError1] = useState({
    index: 0,
    error: "",
  });
  const [imageError2, setImageError2] = useState({
    index: 0,
    error: "",
  });
  const [imageError3, setImageError3] = useState({
    index: 0,
    error: "",
  });
  const [fileData, setFileData] = useState(null);

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "image/heic",
      "image/heif",
      "image/jpeg", // Example image types, add more as needed
      "image/jpg", // Example image types, add more as needed
      "image/png",
      "application/pdf",
      "application/msword", // .doc
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    ];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        // File size exceeds 10 MB
        setError(
          "The file size of your referral document is too large. Please compress it or upload a smaller file."
        );
        setFileData(null); // Clear the selected file
      } else if (!allowedTypes.includes(file.type)) {
        setError(
          "File type not accepted. Please upload a PNG, JPG, PDF, DOCX, or HEIC file."
        );
      } else {
        // File size is within the limit
        setError(""); // Clear any previous error message
        setFileData(file);
      }
    }
  };

  const createFormData = (key, value) => {
    const formBody = new FormData();
    formBody.append("evuid", cookies["evuid"]);
    formBody.append(key, value);
    return formBody;
  };

  const toggleInfoMessage = () => {
    setShowInfoMessage(!showInfoMessage);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
  const dietitianData = useSelector((state) => state.insurence[DIETITIAN_DATA]);

  const [utmParams, setUtmParams] = useState('');

  const getUtmParams = () => {
    const getData = localStorage.getItem('utmParams');

    if (getData) {
      try {
        // Convert query string to an object
        const urlParams = new URLSearchParams(getData);

        // Extract only the required UTM parameters
        const filteredData = {
          UTM_CAMPAIGN: urlParams.get('utm_campaign') || '',
          UTM_MEDIUM: urlParams.get('utm_medium') || '',
          UTM_SOURCE: urlParams.get('utm_source') || '',
          UTM_TERM: urlParams.get('utm_term') || '',
        };
        const jsonString = JSON.stringify(filteredData);

        setUtmParams(jsonString);
        return jsonString;
      } catch (error) {
        console.error('Error processing UTM data:', error);
      }
    }
  }

  console.log(utmParams)

  const { frontInsuranceCardImageUrl, backInsuranceCardImageUrl, frontSecondaryInsuranceCardImageUrl, backSecondaryInsuranceCardImageUrl } =
    useSelector((state) => state.CreditCardInfo);

  const setUserDataHandler = (uData) => {
    if (typeof uData.payment_method_data != "undefined") {
      if (uData.payment_method_data.first_name) {
        setFirstName(uData.payment_method_data.first_name);
      }

      if (uData.payment_method_data.last_name) {
        setLastName(uData.payment_method_data.last_name);
      }
    }

    if (uData.insurence && uData.insurence.insurance_provider_id) {
      setInsurance({
        value: uData.insurence.insurance_provider_id,
        label: uData.insurence.insurance_provider_name,
      });
    } else {
      setInsurance({});
    }

    if (uData && uData.secondary_member_id) {
      setSecondaryMemberID(uData.secondary_member_id);
    } else {
      setSecondaryMemberID(null);
    }

    if (uData && typeof uData.appointment_data != "undefined") {
      setAppointmentData(uData.appointment_data);
    }
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/CreditCardInfo"
              })
            );
            dispatch(
              getDietitian({
                evuid: cookies["evuid"],
              })
            );
            if (firstLoad && resData.data.result.current_step_id && resData.data.result.current_step_id !== "/CreditCardInfo") {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
            window.scrollTo(0, 0);
          } else {
            navigate("/");
          }
          if (firstLoad) {
            logPageView(location.pathname);
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
      getUtmParams();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const setFromInsurenceImage = (imgURL) => {
    dispatch(setFrontInsuranceCardImageUrl(imgURL));
    setError(false);
  };
  const setFrombackInsurenceImage = (imgURL2) => {
    dispatch(setBackInsuranceCardImageUrl(imgURL2));
    setError(false);
  };
  const setFromSecondaryInsurenceImage = (imgURL3) => {
    dispatch(setFrontSecondaryInsuranceCardImageUrl(imgURL3));
    setError(false);
  };
  const setFromSecondarybackInsurenceImage = (imgURL4) => {
    dispatch(setBackSecondaryInsuranceCardImageUrl(imgURL4));
    setError(false);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setError(false);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setError(false);
  };

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const compressImg = async (file) => {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      return null;
    }
  };

  function getTimezone() {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimezone;
  }

  const handleBlur = (e) => {
    if (e.target.name) {
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          payment_method_data: {
            [e.target.name]: e.target.value,
          },
        })
      );
    }

    setFocusedField(null);
    setError(false); // Reset error state on blur
  };

  const handleSelectButton = () => {
    let hasError = false; // Track if there are any errors

    // Check if firstName is empty
    if (firstName.trim() === "") {
      setError("Please complete all required fields.");
      hasError = true;
    }

    // Check if lastName is empty
    if (lastName.trim() === "") {
      setError("Please complete all required fields.");
      hasError = true;
    }

    if (
      !(
        selectedInsurance &&
        (selectedInsurance.label === "Private Pay" ||
          selectedInsurance.label === "HSA / FSA")
      )
    ) {
      if (!frontInsuranceCardImageUrl) {
        setImageError0({
          index: 3,
          error: "Please complete all required fields.",
        });
        hasError = true;
      }
      if (!backInsuranceCardImageUrl) {
        setImageError1({
          index: 3,
          error: "Please complete all required fields.",
        });
        hasError = true;
      }
      if ((selectedInsurance.value === "97214" && secondaryMemberID) && !frontSecondaryInsuranceCardImageUrl) {
        setImageError2({
          index: 3,
          error: "Please complete all required fields.",
        });
        hasError = true;
      }
      if ((selectedInsurance.value === "97214" && secondaryMemberID) && !backSecondaryInsuranceCardImageUrl) {
        setImageError3({
          index: 3,
          error: "Please complete all required fields.",
        });
        hasError = true;
      }
    }

    if (selectedInsurance && (selectedInsurance.value === "97214" ||
      selectedInsurance.value === "60054-Medicare" ||
      selectedInsurance.value === "MABLS-Medicare" ||
      selectedInsurance.value === "63208-Medicare" ||
      selectedInsurance.value === "04271-Medicare" ||
      selectedInsurance.value === "87726-Medicare")) {
      if (!fileData) {
        setError("Please complete all required fields.");
        hasError = true;
      }
    }

    // If any error occurred, return without further processing
    if (hasError) {
      return;
    }

    // If no errors, clear any existing errors and call createTokenHandler
    setError(false);
    // createTokenHandler();
    handleSubmit();
  };

  const handleSubmit = async () => {

    if (!stripe || !elements) {
      console.error("Stripe or Elements not loaded.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      console.error('[Error]', error);
    } else {
      setLoading(true);
      setLoadingText(true);
      let images = [frontInsuranceCardImageUrl, backInsuranceCardImageUrl, frontSecondaryInsuranceCardImageUrl, backSecondaryInsuranceCardImageUrl];
      images = await Promise.all(
        images.map(async (ff) => {
          const compressRes = await compressImg(ff);
          if (compressRes) {
            return compressRes;
          }
          return ff;
        })
      );
      if (token && token.id) {
        const formData = new FormData();
        formData.append("evuid", cookies["evuid"]);
        formData.append("front_image", images[0]);
        formData.append("back_image", images[1]);
        if (images[2]) {
          formData.append("secondary_front_image", images[2]);
        }
        if (images[3]) {
          formData.append("secondary_back_image", images[3]);
        }
        formData.append("token", token.id);
        if (token.card.exp_month && token.card.exp_year) {
          formData.append("expire_month", token.card.exp_month);
          formData.append("expire_year", token.card.exp_year);
        }
        if(utmParams){
          formData.append("utm", utmParams);
        }
        formData.append("time_zone", getTimezone());
        dispatch(
          createPaymentToken(formData, (resData) => {
            setLoading(false);
            setLoadingText(false);
            if (resData && resData.data && resData.data.success) {
              if (fileData) {
                const formBody = createFormData("referral_form", fileData);
                setLoading(true);
                setLoadingText(true);
                dispatch(
                  updateQuickNotes(formBody, (resData) => {
                    setLoading(false);
                    setLoadingText(false);
                    navigate("/appointmentconfirmation");
                  })
                );
              } else {
                navigate("/appointmentconfirmation");
              }
            } else {
              if (
                resData &&
                resData.data &&
                resData.data.result &&
                typeof resData.data.result.time != "undefined"
              ) {
                setError(resData.data.message);
                setTimeout(() => {
                  navigate("/appointmentbooking");
                }, 5000);
              } else if (
                resData &&
                resData.data &&
                resData.data.result &&
                typeof resData.data.result.file_error != "undefined"
              ) {
                setError(resData.data.message);
              } else {
                if (resData && resData.data && resData.data.message) {
                  setError(resData.data.message);
                } else {
                  setError(
                    "Uh oh! Your given information is not valid. Please check that the information above is correct before proceeding."
                  );
                }
              }
            }
          })
        );
      }
    }
  };

  const cardElementBlur = async () => {
    if (!stripe || !elements) {
      console.error("Stripe or Elements not loaded.");
      setErrorMessage("Stripe or Elements not loaded.")
      return;
    }
    setFocusedField(null);
    setError(false);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {!loading && (
        <>
          <Header progressValue={progressValue} styles={{ position: "relative" }} />
          <div className="timer-top-right">
            <CountdownTimer />
          </div>
          <TransitionAnimation>
            <div className="container InsuranceInformation ">
              <div className="row">
                <div className="col-lg-7 col-md-10 col-12 text-left mx-auto mt-4 mb-2">
                  <div className="row top-info-div">
                    <div className="col-auto text-left">
                      <img
                        src={dietitianData.avatar_url || Profile}
                        alt="Dietitian Profile"
                        height="130px"
                        width="130px"
                        className="rounded-circle"
                      />
                    </div>

                    <div className="col text-left d-flex flex-column justify-content-center">
                      <h5 style={{ fontWeight: "540" }}>Session Details</h5>
                      {appointmentData && appointmentData.slot ? (
                        <h6>{moment(appointmentData.slot, 'YYYY-MM-DD HH:mm:ss Z').format('dddd, MMMM D, YYYY')}</h6>
                      ) : (
                        ""
                      )}
                      {appointmentData && appointmentData.slotTime && userData && userData.dietitian_data && userData.dietitian_data.name ? (
                        <h6>
                          {appointmentData.slotTime} with {userData.dietitian_data.name}
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-10 col-sm-12 mx-auto mt-5">
                  <div className="row">
                    <div className="col-10 mx-auto rm-mo-margin mt-2 text-left">
                      <p className=" h4 text-start ">On-File Payment Method</p>
                    </div>
                  </div>
                  <div className="row">
                    {selectedInsurance &&
                      !(
                        selectedInsurance.label === "Private Pay" ||
                        selectedInsurance.label === "HSA / FSA"
                      ) && (
                        <div className=" col-lg-10 col-md-10 col-sm-10 col-10 rm-mo-margin text-left mx-auto">
                          <p className="text-start mt-0 mb-0">
                            {/* <p className="text-start mb-2"> */}
                            Why do we need this?
                            <img
                              src={info}
                              alt="Info Icon"
                              style={{
                                width: "20px", // Set the width of the image
                                height: "20px", // Set the height of the image
                                cursor: "pointer",
                                marginLeft: "20px",
                              }}
                              className="info-icon"
                              onClick={toggleInfoMessage}
                            />
                          </p>
                        </div>
                      )}
                    {selectedInsurance &&
                      !(
                        selectedInsurance.label === "Private Pay" ||
                        selectedInsurance.label === "HSA / FSA"
                      ) &&
                      showInfoMessage && (
                        <div className="info-message mb-3">
                          We require each patient to keep a credit or debit card
                          on file, primarily to enforce our no-show and
                          late-cancellation policy. You <b> will not </b> be
                          charged the no-show or late-cancellation fee unless
                          you either fail to attend your appointment or cancel
                          it within 24 hours of the scheduled time.
                          <br />
                          <br />
                          Everlong’s services are 100% covered by insurance for
                          95% of patients. In rare instances, copays and
                          deductibles may apply, but we strive to keep any
                          out-of-pocket costs to a minimum. Our team is
                          dedicated to helping you navigate your insurance
                          benefits for the best possible coverage.
                        </div>
                      )}
                  </div>
                  <div className="row mt-3">
                    {selectedInsurance &&
                      (selectedInsurance.label === "Private Pay" ||
                        selectedInsurance.label === "HSA / FSA") && (
                        <div className="col-lg-5 col-md-11 col-sm-11 col-11 text-left rm-mo-margin ms-auto">
                          <p className="mb-2">
                            Why do we need this?
                            <img
                              src={info}
                              alt="Info Icon"
                              style={{
                                width: "20px", // Set the width of the image
                                height: "20px", // Set the height of the image
                                cursor: "pointer",
                                marginLeft: "20px",
                              }}
                              className="info-icon"
                              onClick={toggleInfoMessage}
                            />
                          </p>
                        </div>
                      )}
                    {selectedInsurance &&
                      (selectedInsurance.label === "Private Pay" ||
                        selectedInsurance.label === "HSA / FSA") && (
                        <div className="col-lg-5 col-md-11 col-11 me-lg-auto ms-lg-0 ms-auto text-lg-end rm-mo-margin">
                          <p className="cost mb-0">
                            <b>Cost per session: $150</b>
                          </p>
                        </div>
                      )}
                  </div>
                  <div className="row">
                    {selectedInsurance &&
                      (selectedInsurance.label === "Private Pay" ||
                        selectedInsurance.label === "HSA / FSA") &&
                      showInfoMessage && (
                        <div className="info-message mb-3">
                          We require each patient to keep a credit or debit card
                          on file, primarily to enforce our no-show and
                          late-cancellation policy. You <b> will not </b> be
                          charged the no-show or late-cancellation fee unless
                          you either fail to attend your appointment or cancel
                          it within 24 hours of the scheduled time.
                          <br />
                          <br />
                          For private pay or HSA / FSA, your on-file payment
                          method will automatically be charged $150 at the time
                          of each session.
                        </div>
                      )}
                  </div>

                  <div className="row">
                    <div className="col-lg-10 col-md-6 col-sm-12 text-left mx-auto  customcreditcardinput">
                      <div className="row">
                        <div
                          className={`col-md-6 mx-auto mb-3 ${error && !firstName ? "error" : ""
                            }`}>
                          <input
                            type="text"
                            id="firstName"
                            placeholder="First Name"
                            className={`form-control inputbox ${focusedField === "firstName" ? "selected" : ""
                              }`}
                            value={firstName}
                            name="first_name"
                            onChange={handleFirstNameChange}
                            onFocus={() => handleFocus("firstName")}
                            onBlur={handleBlur}
                          // autofocus="true"
                          />
                        </div>
                        <div
                          className={`col-md-6 mx-auto mb-3 ${error && !lastName ? "error" : ""
                            }`}>
                          <input
                            type="text"
                            id="lastName"
                            placeholder="Last Name"
                            name="last_name"
                            className={`form-control inputbox ${focusedField === "lastName" ? "selected" : ""
                              }`}
                            value={lastName}
                            onChange={handleLastNameChange}
                            onFocus={() => handleFocus("lastName")}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-6 col-sm-12 text-left mx-auto mt-0  mb-4 customcreditcardinput">
                      { }
                      <Fragment>
                        <div className="main-div-one">
                          <CardElement
                            id="cardelement"
                            options={{
                              hidePostalCode: true
                            }}
                            onFocus={() => handleFocus("cardelement")}
                            className={`form-control inputbox card-number-element ${focusedField === "cardelement" ? "cardElement-Focus" : ""
                              } ${errorMessage || error ? "error" : ""}`}
                            onBlur={cardElementBlur}
                          />
                        </div>
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                      </Fragment>
                    </div>
                    {selectedInsurance &&
                      (selectedInsurance.label === "Private Pay" ||
                        selectedInsurance.label === "HSA / FSA") ? null : (
                      <>
                        <div className="row">
                          <div className="col-md-10 mt-3  mx-auto customcreditcardinput">
                            <p className="mt-4 mb-0 h4 text-left">
                              Upload Insurance Card
                            </p>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-12 col-md-10 col-sm-12 text-left mx-auto customcreditcardinput">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 ps-3 pe-0 mx-auto text-center mb-1">
                                <InsurenceUpload
                                  accept={"image"}
                                  imageError={imageError0}
                                  index={0}
                                  title={
                                    selectedInsurance && (selectedInsurance.value === "97214" && secondaryMemberID)
                                      ? "Front of Primary Card"
                                      : "Front of Insurance Card"
                                  } setImageError={setImageError0}
                                  fileUrl={frontInsuranceCardImageUrl}
                                  setURL={setFromInsurenceImage}
                                  fileInputRef={fileInputRef} // Pass the ref here
                                  style={{ width: "100%", height: "100%" }} // Apply desired dimensions
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 ps-3 pe-0 mx-auto text-center mb-1">
                                <InsurenceUpload
                                  accept={"image"}
                                  index={0}
                                  fileInputRef={fileInputRef} // Pass the ref here
                                  imageError={imageError1}
                                  setImageError={setImageError1}
                                  title={
                                    selectedInsurance && (selectedInsurance.value === "97214" && secondaryMemberID)
                                      ? "Back of Primary Card"
                                      : "Back of Insurance Card"
                                  }
                                  fileUrl={backInsuranceCardImageUrl}
                                  setURL={setFrombackInsurenceImage}
                                  style={{ width: "100%", height: "100%" }} // Apply the same dimensions
                                />
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </>
                    )}

                    {selectedInsurance &&
                      (selectedInsurance.value === "97214" && secondaryMemberID) ? (
                      <>
                        <div className="row">
                          <div className="col-lg-12 col-md-10 col-sm-12 text-left mx-auto customcreditcardinput">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 ps-3 pe-0 mx-auto text-center mb-3">
                                <InsurenceUpload
                                  accept={"image"}
                                  imageError={imageError2}
                                  index={0}
                                  title="Front of Secondary Card"
                                  setImageError={setImageError2}
                                  fileUrl={frontSecondaryInsuranceCardImageUrl}
                                  setURL={setFromSecondaryInsurenceImage}
                                  fileInputRef={fileInputRef} // Pass the ref here
                                  style={{ width: "100%", height: "100%" }} // Apply desired dimensions
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 ps-3 pe-0 mx-auto text-center mb-3">
                                <InsurenceUpload
                                  accept={"image"}
                                  index={0}
                                  fileInputRef={fileInputRef} // Pass the ref here
                                  imageError={imageError3}
                                  setImageError={setImageError3}
                                  title="Back of Secondary Card"
                                  fileUrl={backSecondaryInsuranceCardImageUrl}
                                  setURL={setFromSecondarybackInsurenceImage}
                                  style={{ width: "100%", height: "100%" }} // Apply the same dimensions
                                />
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </>
                    )
                      : null}

                    {selectedInsurance && (selectedInsurance.value === "97214" ||
                      selectedInsurance.value === "60054-Medicare" ||
                      selectedInsurance.value === "MABLS-Medicare" ||
                      selectedInsurance.value === "63208-Medicare" ||
                      selectedInsurance.value === "04271-Medicare" ||
                      selectedInsurance.value === "87726-Medicare") && (
                        <>
                          <div className="row">
                            <div className="col-md-10 mt-3  mx-auto customcreditcardinput">
                              <p className="mt-4 mb-0 h4 text-left">
                                Upload Referral
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-10 col-sm-12 text-left mx-auto customcreditcardinput">

                              {/* Choose File Button */}
                              <CommonButton
                                className={`image-upload-box d-flex align-items-center justify-content-center customcreditcardinput ${error && !fileData ? "error" : ""
                                  }`}
                                style={{
                                  display: 'flex',
                                  alignItems: 'start',
                                  justifyContent: 'start',
                                  width: '102%',
                                  maxWidth: '',
                                  margin: '20px 0',
                                  padding: '.58rem 4.2rem',
                                  transition: 'all 0.08s ease-in-out 0s',
                                  border: '2px dotted #888',
                                  color: '#888',
                                  backgroundColor: '#FFFFFF',
                                  position: 'relative',
                                }}
                                disabled={false}
                                onClick={() => document.getElementById("fileInput").click()}
                              >
                                <i className="fas fa-plus-circle me-2" style={{ color: '#888' }}></i>
                                Choose File
                                {fileData && (
                                  <i
                                    className="fas fa-check-circle"
                                    style={{
                                      color: 'green',
                                      position: 'absolute',
                                      top: '5px',
                                      right: '5px',
                                      fontSize: '14px',
                                    }}
                                  ></i>
                                )}
                                <input
                                  type="file"
                                  id="fileInput"
                                  disabled={false}
                                  onChange={handleUpload}
                                  className="file-input d-none"
                                />
                              </CommonButton>
                            </div>
                          </div>
                        </>
                      )}

                    <div className="row">
                      {selectedInsurance &&
                        (selectedInsurance.label === "Private Pay" ||
                          selectedInsurance.label === "HSA / FSA") ? null : (
                        <div className="col-lg-10 col-md-10 col-sm-12 text-left mx-auto  customcreditcardinput">
                          {/* <p className="ever-text-last"> */}
                          <Alert severity={"info"} sx={{
                            backgroundColor: "#E6D9F5", color: "#8548D3", width: "102%",
                            '& .MuiAlert-icon': {
                              color: "#8548D3",
                              marginRight: "10px"
                            }
                          }}>
                            Everlong’s services are 100% covered by insurance
                            for 95% of patients. In rare instances, copays and
                            deductibles may apply, but we strive to keep any
                            out-of-pocket costs to a minimum. Our team is
                            dedicated to helping you navigate your insurance
                            benefits for the best possible coverage.
                          </Alert>
                          {/* </p> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-8 col-sm-12 col-10 mx-auto">
                <div className="mt-3 "></div>
                {error && (
                  <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Alert
                      severity="error"
                      // className="mt-3"
                      style={{ display: "inline-flex" }}
                    >
                      {error}
                    </Alert>
                  </div>
                )}
                {errorMessage && (
                  <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Alert
                      severity="error"
                      // className="mt-3"
                      style={{ display: "inline-flex" }}
                    >
                      {errorMessage}
                    </Alert>
                  </div>
                )}
                {!error && (imageError0.error || imageError1.error || imageError2.error || imageError3.error) && (
                  <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Alert
                      severity="error"
                      style={{ display: "inline-flex" }}
                    >
                      {[
                        imageError0.error,
                        imageError1.error,
                        imageError2.error,
                        imageError3.error,
                      ].find((err) => err) || ""}
                    </Alert>
                  </div>
                )}
                <CommonButton
                  className={` ${error ? "selected" : ""}`}
                  onClick={handleSelectButton}
                  disabled={loading}>
                  Confirm Appointment
                </CommonButton>
              </div>
              {/* <CountdownTimer /> */}
            </div>
          </TransitionAnimation>
        </>
      )}
      {loading && (
        <div className="container page-main1 ">
          <div className="row">
            <Spinner text="Please wait" />
          </div>
          {loadingText && (
            <div className="row mt-3">
              <p>Confirming your session…Please do not leave this page.</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CreditCardInfo;
