import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useCookies } from "react-cookie";
import { getUserData, updateUserData } from "../redux/action/userAction";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { getDietitian } from "../redux/action/insurenceProviderAction";
import { DIETITIAN_DATA } from "../utils/constants/insurence-provider";

import Header from "./header";
import "../css/welcome.css";
import "aos/dist/aos.css";
import "../../src/css/DietitianScreen.css"
import Profile from "../images/pro.png"; // Import the profile image
import moment from "moment";
import CountdownTimer from "./CountdownTimer";
import { dateFormate } from "../utils/helpers/CommonHelper";
import Spinner from "./Spinner";
import TransitionAnimation from "./Animation/TransitionAnimation";
import { useLocation } from "react-router-dom";
import { logPageView } from "../utils/analytics";

const DietitianScreen = () => {
  const [cookies, setCookie] = useCookies(["evuid"]);
  const dispatch = useDispatch();
  const progressValue = 65;
  const navigate = useNavigate();

  const userData = useSelector((state) => state.user[USER_DATA]);
  const dietitianData = useSelector((state) => state.insurence[DIETITIAN_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
  const location = useLocation();

  const getUser = () => {
    // dispatch(clearDietitianData())
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/appointmentbooking",
              })
            );
            dispatch(
              getDietitian({
                evuid: cookies["evuid"],
              })
            );
            if (firstLoad && resData.data.result.current_step_id && resData.data.result.current_step_id !== "/appointmentbooking") {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
            //window.scrollTo(0, 0);
          } else {
            navigate("/");
          }
          if(firstLoad) {
            logPageView(location.pathname);
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    }
    navigate('/appointmentbooking');
    // getUser();
  }, [cookies["evuid"]]);

  const handleContinueButton = () => {
    navigate("/referred");
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 900);
  }, []);
  return (
    <>
      <Header progressValue={progressValue} />
      {dietitianData && dietitianData.id && (
        <TransitionAnimation>
          <div className="container page-main1 diet-main-container h-auto ">
            <div className="row dietitian-screen-block">
              <div className="col-auto text-left">
                <img
                  src={dietitianData.avatar_url || Profile}
                  alt="Dietitian Profile"
                  height={120}
                  width={120}
                  className="rounded-circle"
                />
              </div>

              <div className="col text-left">
                <h5 style={{ fontWeight: "500" }}>Your selected appointment:</h5>
                {userData && userData.appointment_data && userData.appointment_data.slot ? (
                  <div>
                    <h6>{dateFormate(userData.appointment_data.slot)}</h6>
                    <h6>
                      {moment(userData.appointment_data.slot, "YYYY-MM-DD HH:mm:ss Z").format("h:mm A")}{" "}
                      with {dietitianData.first_name} {dietitianData.last_name}
                    </h6>
                  </div>
                ) : (
                  ""
                )}
                <CountdownTimer callFrom="dietitianScreen" />
              </div>
              {/* <div className="mt-1">
    <CountdownTimer />
  </div> */}
            </div>
          </div>

        </TransitionAnimation>
      )}
      {!dietitianData.id && (
        <div className="container page-main1 ">
          <div className="row">
            <Spinner text="Please wait" />
          </div>
        </div>
      )}
    </>
  );
};

export default DietitianScreen;
